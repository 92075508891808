.swiper-container {
  .swiper-pagination-container {
    position: absolute;
    left: 50%;
    bottom: 15px;
    width: 100%;

    .swiper-pagination {
      display: block;
      position: relative;
      left: -50%;

      .swiper-pagination-bullet {
        margin: 0 2px;
        border: 1px solid #fff;
        background-color: transparent;

        &.swiper-pagination-bullet-active {
          background-color: #fff;
        }
      }
    }
  }
}
