.banner {
  position: relative;
  height: 150px;
  margin: 5px 0;
  overflow: hidden;

  img {
    left: 100%;
    position: relative;
    width: 150px;
    height: calc(100vw - 60px);
    transform: rotate(90deg);
    transform-origin: top left;
    object-fit: cover;
  }

  .caption {
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 30px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 13px;
    line-height: 20px;
    text-transform: uppercase;
    opacity: 0;
    transition: all 0.5s ease-in-out;

    &:hover {
      opacity: 1;
    }
    .content{
      width: 100%;

      .next {
        margin-top: -10px;
        border-top: 1px solid #fff;
        text-align: right;
      }
    }
  }
}

@media (min-width: $sm-view) {
  .banner {
    display: inline-block;
    width: calc(20% - 9px);
    height: auto;
    margin: 0 3px;

    img {
      position: static;
      width: 100%;
      height: auto;
      transform: none;
      object-fit: initial;
    }
  }
}
