.voucher-form {

  .row {
    margin-bottom: 15px;
    label {
      font-weight: 600;
      text-align: left;
      text-transform: uppercase;
    }
    input {
      display: block;
      border: none;
      background-color: $inputBackground;
      width: 100%;
      height: 34px;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857143;
    }
  }

  button {
    border: none;
    border-radius: 0;
    background-color: transparent;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;

    .fa {
      margin-left: 0;
      font-size: 19px;
      line-height: 12px;

      &:first-child {
        margin-left: 3px;
      }
    }
  }
}

.confirm-message {
  pre {
    border: none;
    border-radius: 0;
    background-color: $grey;
    color: white;

  }
}