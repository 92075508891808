$grey: #575756;
$cyan: #005578;
$magenta: #b7156e;
$darkgreen: #37613d;
$yellow: #e6d715;
$inputBackground: #dfdfdf;

@import "font-face-mixin.scss";
@include font-face("Raleway", "Medium", $packageKey: "Bergerhausen.MainWebsite");
@include font-face("Raleway", "MediumItalic", $packageKey: "Bergerhausen.MainWebsite");
@include font-face("Raleway", "SemiBold", $packageKey: "Bergerhausen.MainWebsite");
@include font-face("Raleway", "SemiBoldItalic", $packageKey: "Bergerhausen.MainWebsite");
@include font-face("Raleway", "Bold", $packageKey: "Bergerhausen.MainWebsite");
@include font-face("Raleway", "BoldItalic", $packageKey: "Bergerhausen.MainWebsite");

html {
    body {
        margin: 0 15px;
        padding: 0;
        color: $grey;
        font-family: 'Raleway', sans-serif;
        font-size: 12px;
        font-weight: 500;
        word-wrap: break-word;
        text-rendering: optimizeLegibility !important;
        -webkit-font-smoothing: antialiased !important;
        .modal {
            z-index: 99999;
        }
        .cc-window {
            z-index: 99999;
        }
        .cc-grower {
            position: fixed;
            z-index: 99999;
            top: 0;
            left: 0;
            // Prevent popup from exceeding the viewport
            max-height: 100vh !important;
            overflow: auto;
            .cc-message {
                max-height: 70vh;
                overflow: auto;
            }
            // END Prevent popup from exceeding the viewport
        }
        .cc-revoke {
            position: fixed;
            z-index: 1031;
            @media (hover: none) {
                right: 0 !important;
                left: unset !important;
                top: 80px !important;
                padding: 5px 8px;
                border-radius: 10px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0 !important;
            }
            @media (hover: hover) {
                left: 2em !important;
                right: unset !important;
                background-color: rgb(244, 244, 244) !important;
            }
        }
        h1,
        h2 {
            font-size: 30px;
            font-weight: 600;
            text-transform: uppercase;
        }
        h2 {
            font-size: 25px;
        }
        h3,
        h4 {
            margin: 20px 0 0;
            padding: 5px;
            font-size: 22px;
            font-weight: 700;
            text-transform: uppercase;
            a {
                display: block;
                color: inherit;
                text-decoration: none;
                &:hover,
                &:focus,
                &:active {
                    color: inherit;
                    text-decoration: none;
                }
            }
        }
        h4 {
            margin-top: 10px;
            font-size: 18px;
        }
        &.cyan h3,
        &.cyan h4 {
            color: #fff;
            background-color: $cyan;
        }
        &.magenta h3,
        &.magenta h4 {
            color: #fff;
            background-color: $magenta;
        }
        &.darkgreen h3,
        &.darkgreen h4 {
            color: #fff;
            background-color: $darkgreen;
        }
        &.yellow h3,
        &.yellow h4 {
            color: $grey;
            background-color: $yellow;
        }
        &.key h3,
        &.key h4 {
            color: #fff;
            background-color: $grey;
        }
        p {
            margin: 15px 0;
        }
        a {
            color: inherit;
            &:hover,
            &:focus,
            &:active {
                color: inherit;
            }
        }
        hr {
            border-color: $grey;
        }
        @import 'header.scss';
        @import 'menu.scss';
        @import 'modal.scss';
        @import 'swiper.scss';
        @import 'banner.scss';
        @import 'gallery.scss';
        @import 'lightbox.scss';
        @import 'table.scss';
        @import 'form.scss';
        @import 'voucher-form.scss';
        @import 'web-game.scss';
        main {
            margin: 30px 0 0;
            .back-link {
                display: none;
            }
            article {
                position: relative;
                margin-bottom: 70px;
                &:last-of-type {
                    margin-bottom: 0;
                    padding-bottom: 15px;
                }
                a {
                    font-weight: 700;
                }
                .crop-mark {
                    &.tl,
                    &.tr,
                    &.bl,
                    &.br {
                        position: absolute;
                        top: 5px;
                        left: -20px;
                        width: 15px;
                        border-top: 1px solid $grey;
                        &:after {
                            content: '';
                            position: absolute;
                            top: -26px;
                            left: 25px;
                            height: 15px;
                            border-left: 1px solid $grey;
                        }
                    }
                    &.tr,
                    &.br {
                        left: auto;
                        right: -20px;
                        &:after {
                            left: auto;
                            right: 25px;
                        }
                    }
                    &.bl,
                    &.br {
                        top: auto;
                        bottom: 5px;
                        &:after {
                            top: auto;
                            bottom: -25px;
                        }
                    }
                    &.tc {
                        position: absolute;
                        top: -20px;
                        left: calc(50% - 7px);
                    }
                    &.cl {
                        position: absolute;
                        top: calc(50% - 8px);
                        left: -20px;
                    }
                    &.cr {
                        position: absolute;
                        top: calc(50% - 8px);
                        right: -20px;
                    }
                }
            }
            #teaser-modal {
                .modal-dialog {
                    background: none;
                    margin: 0 auto;
                    margin-top: 20px;
                    border-radius: 3px;
                    width: fit-content;
                    .modal-content {
                        overflow: hidden;
                        max-height: 90vh;
                        .modal-body {
                            min-width: 400px;
                        }
                    }
                }
                button {
                    position: absolute;
                    right: 5px;
                    top: 5px;
                    z-index: 50;
                    text-shadow: 1px 1px 5px $grey;
                    margin: 0;
                }
            }
        }
        .modal-backdrop.in {
            opacity: .75;
        }
        @import 'footer.scss';
        @media (min-width: $sm-view) {
            h1 {
                font-size: 17px;
            }
            h2 {
                font-size: 17px;
            }
            h3 {
                font-size: 16px;
            }
            h4 {
                font-size: 13px;
            }
            main article:last-of-type {
                margin-bottom: 0;
                padding: 0 15px 15px;
            }
        }
        @media (min-width: $md-view) {
            h1 {
                font-size: 24px;
            }
            h2 {
                font-size: 24px;
            }
            h3 {
                font-size: 19px;
            }
            h4 {
                font-size: 16px;
            }
        }
        @media (min-width: $lg-view) {
            h1 {
                font-size: 30px;
            }
            h2 {
                font-size: 28px;
            }
            h3 {
                font-size: 22px;
            }
            h4 {
                font-size: 18px;
            }
        }
    }
}