&.gutscheincode {
  header {
    .navbar {
      .navbar-header {
        .navbar-toggle {
          display: none !important;
        }
        .icon-group {
          display: none !important;
        }
      }
    }
  }
}

header {
  .navbar {
    .navbar-header {
      .navbar-toggle {
        display: block;
        margin-right: 0;
        padding-right: 0;

        .icon-bar {
          width: 30px;
          height: 5px;
          background-color: $grey;
          border-radius: 1px;
          transition: all 0.3s ease-in-out;
        }

        &.modal-open .icon-bar {
          background-color: transparent;
        }
      }

      .navbar-brand {
        max-width: calc(100% - 50px);
      }

      .header-img {
        max-height: 80px;
        width: auto;
        margin-left: 5px;

        display: none;
        @media (min-width: $sm-view) {
          display: block;
        }

        padding-top: 15px;
        padding-left: 30px;
      }
    }
  }

  #navbar-collapse {
    .modal-content {
      .nav {
        li {
          margin: 0;

          a {
            padding: 20px 0 0;
            color: #fff;
            font-size: 27px;
            font-weight: 600;
            text-transform: uppercase;

            &:hover,
            &:focus,
            &:active {
              background-color: transparent;
            }
          }
          &.active>a {
            background-color: transparent;
            text-decoration: underline;
          }

          .sub-items {
            -webkit-column-break-inside: avoid;
            page-break-inside: avoid;
            break-inside: avoid-column;
            display:table;

            a {
              padding: 0 0 0 10px;
              font-size: 17px;

              &:before {
                content: '';
                display: block;
                position: absolute;
                top: 12px;
                left: 0;
                width: 5px;
                border-top: 2px solid #fff;
              }
            }
          }
        }
      }

      .menu-text {
        margin-top: 30px;
        padding-top: 10px;
        color: #fff;
        border-top: 1px solid #fff;
        font-size: 14px;
      }
    }
  }

  @media (min-width: $sm-view) {
    .navbar .navbar-header {
      width: 100%;

      .icon-group {
        float: right;
        margin: 14px 5px 0 0;
        font-size: 22px;

        a {
          margin-right: 3px;
        }
      }
    }

    #navbar-collapse {
      .modal-content .nav {
        column-count: 2;

        &>li {
          break-inside: avoid;
          -webkit-column-break-inside: avoid;
          break-inside: avoid-column;
          page-break-inside: avoid;
          display: table;
        }
      }
    }
  }
}
