.web-game {
  .game-logo {
    max-width: 150px;
    #open-game {
      cursor: pointer;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.modal {
  .modal-lg {
    width: 980px;
    .modal-content {
      iframe {
        border: none;
      };
    }
  }
}
