.gallery-headline + hr {
  margin-top: 0;
}

.gallery {
  padding: 0 30px;

  .prev-slide,
  .next-slide {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 100%;
    background-color: #fff;
    font-size: 40px;
    z-index: 999;

    i {
      position: relative;
      cursor: pointer;
      opacity: 1;
      transition: all 0.5s ease-in-out;
    }

    &.overlay-open i {
      opacity: 0 !important;
      cursor: auto;
    }
  }

  .prev-slide i {
    opacity: 0.3;
  }

  .next-slide {
    left: auto;
    right: 0;
    padding-left: 8px;
  }

  .gallery-item {
    width: 100%;
    margin-bottom: 15px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    cursor: pointer;

    &.landscape {
     height: 90px;
    }

    &.portrait {
     height: 195px;
    }

    .caption {
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% - 30px);
      height: 100%;
      margin-left: 15px;
      padding: 30px;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.5);
      font-size: 18px;
      line-height: 20px;
      text-transform: uppercase;
      opacity: 0;
      transition: all 0.5s ease-in-out;

      &:hover {
        opacity: 1;
      }
      .content{
        top: 5px;
        width: calc(100% - 30px);
        height: 100%;
        margin-left: 15px;
        text-align: center;

        .next {
          position: absolute;
          bottom: 15px;
          width: 100%;
          margin-top: -10px;
          border-top: 1px solid #fff;
          font-size: 13px;
          text-align: right;
       }
      }
    }

    .overlay {
      position: fixed;
      top: 100%;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;
      transition: top 0.5s ease-in-out, opacity 0.5s ease-in-out;
      cursor: auto;
      opacity: 0;
      z-index: 9;

      &.open {
        top: 0;
        opacity: 1;
      }

      .close {
        width: 30px;
        height: 30px;
        margin: 8px 8px 5px 0;
        font-size: 37px;
        opacity: 1;

        .icon-close {
          display: block;
          width: 30px;
          height: 4px;
          background-color: $grey;
          border-radius: 1px;
          transform: rotate(45deg);

          &:after {
            content: '';
            display: block;
            width: 30px;
            height: 4px;
            background-color: $grey;
            border-radius: 1px;
            transform: rotate(-90deg);
          }
        }
      }

      .img-wrapper {
        height: 40%;
        padding: 15px;
        background-color: #f5f5f5;

        img {
          width: auto;
          max-height: 100%;
          margin: 0 auto;
        }
      }

      .text-left,
      .text-right {
        display: inline-block;
        width: 100%;
        margin-top: 15px;
        padding: 15px;
        background-color: #f5f5f5;
        text-align: left;
      }
    }
  }

  @media (min-width: $sm-view) {
    .gallery-item {
      &.landscape {
        height: 90px;
      }

      &.portrait {
        height: 195px;
      }

      .caption {
        height: calc(100% - 15px);
      }
    }
  }

  @media (min-width: $md-view) {
    .gallery-item {
      &.landscape {
        height: 120px;
      }

      &.portrait {
        height: 255px;
      }

      .overlay {
        .img-wrapper {
          height: 50%;
        }

        .text-left {
          width: 60%;
          float: left;
        }

        .text-right {
          width: 30%;
          float: right;
        }
      }
    }
  }

  @media (min-width: $lg-view) {
    .gallery-item {
      &.landscape {
        height: 150px;
      }

      &.portrait {
        height: 315px;
      }

      .overlay .img-wrapper {
        height: 60%;
      }
    }
  }
}
