.ajax-form {
  margin-bottom: 30px;
  text-align: center;

  i.fa-spinner {
    margin-top: 50px;
    font-size: 50px;
  }

  form.form-horizontal {
    .control-label {
      font-weight: 600;
      text-align: left;
      text-transform: uppercase;
    }

    .checkbox label, .radio label {
      text-align: left;
    }

    .help-block {
      text-align: left;
    }

    .form-control {
      background-color: $inputBackground;
      border: none;
      border-radius: 0;
      box-shadow: none;
    }

    .actions  .nav li {
      float: right;

      .btn {
        padding: 0;
        color: $grey;
        border: none;
        border-radius: 0;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;

        &:hover,
        &:focus,
        &:active {
          background-color: transparent;
        }

        span {
          margin-right: 3px;
        }

        i {
          margin-left: 0;
          font-size: 19px;
          line-height: 12px;
        }
      }
    }
  }
}
