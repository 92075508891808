.modal {
  .close {
    width: 30px;
    height: 30px;
    margin-top: 15px;
    margin-right: 2px;
    color: #fff;
    font-size: 37px;
    opacity: 1;

    .icon-close {
      display: block;
      width: 30px;
      height: 5px;
      background-color: #fff;
      border-radius: 1px;
      transform: rotate(45deg);

      &:after {
        content: '';
        display: block;
        width: 30px;
        height: 5px;
        background-color: #fff;
        border-radius: 1px;
        transform: rotate(-90deg);
      }
    }
  }
  .modal-content {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }

  @media (min-width: $sm-view) {
    .close {
      margin-right: 17px;
    }
  }
}
