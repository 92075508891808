div.pkcp {
    margin-left: -15px !important;

    h2 {
        text-transform: initial !important;
    }

    h3, h4 {
        background-color: transparent !important;
        text-transform: initial !important;
        padding: initial !important;
    }

    h3 {
        margin: 20px 0 10px !important;
    }

    h4 {
        margin: 10px 0 !important;
    }

    p {
        margin: 0 0 10px !important;
    }
}