&.gutscheincode {
  footer {
    .footer-wrapper-left {
      .nav {
        display: none;
      }
    }
  }
}

footer {
  margin: 0 30px;
  padding: 10px 0 0;
  border-top: 1px solid $grey;

  .footer-wrapper-left {
    .footer-left {
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
    }

    .nav {
      margin: 15px 0;

      li {
        a {
          padding: 0 3px 0 0;
          color: $grey;
          font-size: 14px;
          font-weight: 500;
          text-transform: uppercase;

          &:hover,
          &:focus,
          &:active {
            background-color: transparent;
            text-decoration: none;
          }

          &:before {
            content: '-';
            display: inline-block;
            padding-right: 3px;
          }
        }
      }
    }
  }

  .footer-spacer {
    display: none;
  }

  .footer-wrapper-right {
    display: flex;
    flex-direction: column-reverse;

    p {
      margin: 5px 0;
      font-size: 14px;

      a {
        color: $grey;
        font-weight: 500;

        &:hover,
        &:focus,
        &:active {
          text-decoration: none;
        }
      }
    }
  }

  @media (min-width: $sm-view) {
    .footer-wrapper-left {
      float: left;
      margin-right: 10px;

      .footer-left {
        font-size: 23px;
        font-weight: 500;
      }

      .nav {
        margin: 0 0 15px;

        li {
          float: left;

          a {
            font-size: 15px;

            &:before {
              content: '';
              padding: 0;
            }
          }

          & + li a:before {
            content: '//';
            display: inline-block;
            padding-right: 3px;
          }
        }
      }
    }

    .footer-spacer {
      display: block;
      height: 28px;
      margin-top: 8px;
      background-repeat: space no-repeat;
      overflow: hidden;
    }

    .footer-wrapper-right {
      display: block;
      float: right;

      p {
        float: right;
        margin: 0 0 15px 15px;
        font-size: 15px;
      }
    }
  }
}
