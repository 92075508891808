.typo3-neos-nodetypes-menu .nav-pills {
  li {
    a {
      padding: 5px 0;
      &:hover {
        background-color: transparent;
        text-decoration: underline;
      }
    }

    &.active a {
      color: $grey;
      background-color: transparent;
      text-decoration: underline;
    }

    ul {
      margin-bottom: 10px;
    }
  }
}
