a.swipebox {
  display: block;
}

#swipebox-overlay {
  background-color: rgba(0, 0, 0, 0.75);

  #swipebox-top-bar {
    top: -60px;
    height: 60px;
    background-color: rgba(0, 0, 0, 0.35);
    font-family: 'Raleway', sans-serif;
    -webkit-transform: translate3d(0,60px,0);
    transform: translate3d(0,60px,0);

    #swipebox-title {
      padding-top: 8px;
      padding-left: 10px;
      font-size: 18px;
      font-weight: bold;
      text-align: left;
      text-transform: uppercase;
    }
  }

  #swipebox-close {
    display: block;
    top: 27px;
    right: 10px;
    width: 30px;
    height: 5px;
    background-image: none !important;
    background-color: #fff;
    border-radius: 1px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);

    &:before {
      content: '';
      position: absolute;
      top: -12px;
      left: 0;
      width: 100%;
      height: 30px;
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    &:after {
      content: '';
      display: block;
      width: 30px;
      height: 5px;
      background-color: #fff;
      border-radius: 1px;
      -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg);
    }
  }
}

@media (min-width: $sm-view) {
  #swipebox-overlay{
    #swipebox-top-bar #swipebox-title {
      padding-left: 0;
      text-align: center;
    }

    #swipebox-close {
      right: 31px;
    }
  }
}
